import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import { fetchSearchResults } from '../api'
import { fetchMakeModelBySlug } from '../api'
import ads from './ads';
import dealers from './dealers';
import reviews from './reviews';
import industrynews from './industrynews';
import breadcrumbs from './breadcrumbs';

var numeral = require('numeral');

function stringToSlug (str) {
    if( typeof str !== 'undefined'){
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();
    }

        // remove accents, swap ñ for n, etc
        var from = "àáäâèéëêìíïîòóöôùúüûñç·/,:;";
        var to   = "aaaaeeeeiiiioooouuuunc------";

        for (var i = 0, l = from.length; i < l; i++) {
            str = typeof str !== 'undefined' ? str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)) : str;
        }

        str = typeof str !== 'undefined' ? str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '+') // collapse whitespace and replace by -
            .replace(/-+/g, '+') : str; // collapse dashes

    return str;
}

function checkFriendlyUrls(commit, slug){
    if(slug.startsWith('5-star-safety')) {
        commit('setSearchSafetyRating', [5]);
    }else if(slug.startsWith('7-seaters')){
        commit('setSearchSeats', [7]);
    }else if(slug.startsWith('8-plus-seaters') ) {
        commit('setSearchSeats', [8]);
    }else if(slug.startsWith('automatics')){
        commit('setSearchTransmissionType', ['automatic']);
    }else if(slug.startsWith('classic')){
        commit('setSearchYearTo', new Date().getFullYear() - 30);
    }else if(slug.startsWith('eco-friendly')){
        commit('setSearchEnvironmental', [5]);
    }else if(slug.startsWith('electric')){
        commit('setSearchFuelType', ['electric']);
    }else if(slug.startsWith('fast')){
        commit('setSearchAcceleration', ['0+8']);
    }else if(slug.startsWith('hybrids')){
        commit('setSearchFuelType', [
            'hybrid+diesel+plug+in',
            'hybrid+unspecified',
            'hybrid+diesel',
            'hybrid+petrol',
            'hybrid+petrol+plug+in',
        ]);
    }else if(slug.startsWith('low-running-costs')){
        commit('setSearchEnginePower', ['70+100']);
    }else if(slug.startsWith('luxury')){
        commit('setSearchPriceFrom', 70000);
        commit('setMonthlyPriceSwitch', false);
    }else if(slug.startsWith('powerful')){
        commit('setSearchEnginePower', ['250+300']);
    }else if(slug.startsWith('pretty')){
        commit('setSearchMakeDesktop', ['mini','fiat', 'volkswagen']);
        commit('setSearchModelDesktop', ['500l', '500x', '500', 'beetle', 'cooper', 'one']);
    }else if(slug.startsWith('suv')){
        commit('setSearchBodyType', ['suv']);
    }else if(slug.startsWith('vans')){
        commit('setSearchBodyType', ['van']);
    }else if(slug.startsWith('wheelchair')){
        commit('setSearchBodyType', ['wheelchair+accessible']);
    }
}

export function createStore() {
    return new Vuex.Store({
        state: () => ({
            openMobileRefine: false,
            mobileView: 'list',
            valueConsistsOf: 'LEAF_PRIORITY',

            makes: null,
            models: null,
            makeModels: null,
            years: null,
            yearsFrom: null,
            yearsTo: null,
            prices: null,
            pricesFrom: null,
            pricesTo: null,
            deposits: null,
            monthlyPrices: null,
            monthlyPricesFrom: null,
            monthlyPricesTo: null,
            locations: null,
            counties: null,
            mileages: null,
            mileagesFrom: null,
            mileagesTo: null,
            kilometres: null,
            kilometresFrom: null,
            kilometresTo: null,
            engineSizes: null,
            engineSizesFrom: null,
            engineSizesTo: null,
            transmissionTypes: null,
            fuelTypes: null,
            bodyTypes: null,
            colours: null,
            seats: null,
            doors: null,
            safetyRatings: null,
            fuelConsumption: null,
            environmental: null,
            enginePowers: null,
            accelerations: null,
            financeDeposits: null,
            monthlyPriceSwitch: false,
            mileageSwitch: 'mile',
            dealer: null,
            searchMake: [],
            searchMakeDesktop: [],
            searchModel: [],
            searchModelDesktop: [],
            searchYearFrom: null,
            searchYearTo: null,
            searchPriceFrom: null,
            searchPriceTo: null,
            searchLocations: [],
            searchProvinces: [],
            searchMileageFrom: null,
            searchMileageTo: null,
            searchKilometresFrom: null,
            searchKilometresTo: null,
            searchEngineSizeFrom: null,
            searchEngineSizeTo: null,
            searchMonthlyPricesFrom: null,
            searchMonthlyPricesTo: null,
            searchTransmissionType: [],
            searchFuelType: [],
            searchBodyType: [],
            searchColour: [],
            searchSeats: [],
            searchDoors: [],
            searchSafetyRating: [],
            searchFuelConsumption: [],
            searchEnvironmental: [],
            searchEnginePower: [],
            searchAcceleration: [],
            searchFinanceDeposit: null,
            s: null, // internal source

            resultsLoaded: false,
            searchResults: {},
            searchFilters: [],

            requestedPage: null,

            currentPageName: null,
            currentPage: 1,
            from: 0,
            lastPage: 0,
            to: 0,
            perPage: 0,
            total: 0,
            searchOrderBy: 'price-desc',
            manualSortBy: false,
            pagination: {},
            backendPagination: [],
            urlParams: {},
            url: process.env.VUE_APP_API_URL + '/api/ad-elastic-filters?',
            currentFilter: '',
            justLoaded: false,
            isLoading: false,
            showEmailAlert: false,
            mapStartLocation: '',
            listViewMode: 'list',
            quickSearchOverlay: false
        }),
        mutations: {
            setListViewMode(state, mode){
                state.listViewMode = mode;
            },

            setOpenMobileRefine(state) {
                state.openMobileRefine = true;
            },

            resetOpenMobileRefine(state) {
                state.openMobileRefine = false;
            },

            setCurrentFilter(state, currentFilter) {
                state.currentFilter = currentFilter;
            },

            setSearchMakeDesktop(state, searchMake) {
                state.searchMakeDesktop = searchMake;
                if (state.searchMakeDesktop.length == 0) {
                    state.justLoaded = false;
                    state.searchModelDesktop = [];
                }
            },

            setSearchModel(state, searchModel) {
                state.searchModel = searchModel;
            },

            setSearchModelDesktop(state, searchModel) {
                state.searchModelDesktop = searchModel;
            },

            setSearchYearFrom(state, searchYearFrom) {
                state.searchYearFrom = searchYearFrom;
            },

            setSearchYearTo(state, searchYearTo) {
                state.searchYearTo = searchYearTo;
            },

            setSearchPriceFrom(state, searchPriceFrom) {
                state.searchPriceFrom = searchPriceFrom;
            },

            setSearchPriceTo(state, searchpriceTo) {
                state.searchPriceTo = searchpriceTo;
            },

            setSearchMonthlyPricesFrom(state, searchMonthlyPricesFrom) {
                state.searchMonthlyPricesFrom = searchMonthlyPricesFrom;
            },

            setSearchMonthlyPricesTo(state, searchMonthlyPricesTo) {
                state.searchMonthlyPricesTo = searchMonthlyPricesTo;
            },

            setSearchLocations(state, searchLocations) {
                state.searchLocations = searchLocations;
                if (state.searchLocations.length == 0) {
                    state.justLoaded = false;
                }
            },

            pushSearchLocations(state, location) {
                if (!state.searchLocations.includes(location)) {
                    state.searchLocations.push(location);
                }
            },

            popSearchLocations(state, location) {
                if (state.searchLocations.length > 0) {
                    state.searchLocations = state.searchLocations.filter(function (value) {
                        return value != location;
                    });
                    if (state.searchLocations.length == 0) {
                        state.justLoaded = false;
                        state.currentFilter = '';
                    }
                } else {
                    state.searchLocations = [];
                }
            },
            setSearchProvinces(state, searchProvinces) {
                state.searchProvinces = searchProvinces;
            },

            setSearchMileageFrom(state, searchMileageFrom) {
                state.searchMileageFrom = searchMileageFrom;
            },

            setSearchMileageTo(state, searchMileageTo) {
                state.searchMileageTo = searchMileageTo;
            },

            setSearchKilometresFrom(state, searchKilometresFrom) {
                state.searchKilometresFrom = searchKilometresFrom;
            },

            setSearchKilometresTo(state, searchKilometresTo) {
                state.searchKilometresTo = searchKilometresTo;
            },

            setSearchEngineSizeFrom(state, searchEngineSizeFrom) {
                state.searchEngineSizeFrom = searchEngineSizeFrom;
            },

            setSearchEngineSizeTo(state, searchEngineSizeTo) {
                state.searchEngineSizeTo = searchEngineSizeTo;
            },

            setSearchTransmissionType(state, searchTransmissionType) {
                state.searchTransmissionType = searchTransmissionType;
            },

            setSearchFuelType(state, searchFuelType) {
                state.searchFuelType = searchFuelType;
            },

            setSearchBodyType(state, searchBodyType) {
                state.searchBodyType = searchBodyType;
            },

            setSearchColour(state, searchColour) {
                state.searchColour = searchColour;
            },

            setSearchSeats(state, searchSeats) {
                state.searchSeats = searchSeats;
            },

            setSearchDoors(state, searchDoors) {
                state.searchDoors = searchDoors;
            },

            setSearchSafetyRating(state, searchSafetyRating) {
                state.searchSafetyRating = searchSafetyRating;
            },

            setSearchFuelConsumption(state, searchFuelConsumption) {
                state.searchFuelConsumption = searchFuelConsumption;
            },

            setSearchEnvironmental(state, searchEnvironmental) {
                state.searchEnvironmental = searchEnvironmental;
            },

            setSearchEnginePower(state, searchEnginePower) {
                state.searchEnginePower = searchEnginePower;
            },

            setSearchAcceleration(state, searchAcceleration) {
                state.searchAcceleration = searchAcceleration;
            },

            setSearchFinanceDeposit(state, searchFinanceDeposit) {
                state.searchFinanceDeposit = searchFinanceDeposit;
            },

            setMakes(state, makes) {
                state.makes = makes;
            },

            setModels(state, models) {
                state.models = models;
            },

            setMakeModels(state, models) {
                state.makeModels = models;
            },

            setYears(state, years) {
                state.years = years;
            },
            setYearsFrom(state, years) {
                state.yearsFrom = years;
            },
            setYearsTo(state, years) {
                state.yearsTo = years;
            },

            setPrices(state, prices) {
                state.prices = prices;
            },
            setPricesFrom(state, prices) {
                state.pricesFrom = prices;
            },
            setPricesTo(state, prices) {
                state.pricesTo = prices;
            },

            setFinanceDeposits(state, financeDeposits) {
                state.financeDeposits = financeDeposits;
            },

            setFreeText(state, freeText){
                state.freeText = freeText;
            },

            setFreeTextAny(state, freeTextAny){
                state.freeTextAny = freeTextAny;
            },

            setLocations(state, locations) {
                state.locations = locations;
            },

            setCounties(state, counties) {
                state.counties = counties;
            },

            setMileages(state, mileages) {
                state.mileages = mileages;
            },
            setMileagesFrom(state, mileages) {
                state.mileagesFrom = mileages;
            },
            setMileagesTo(state, mileages) {
                state.mileagesTo = mileages;
            },

            setKilometres(state, kilometres) {
                state.kilometres = kilometres;
            },
            setKilometresFrom(state, kilometres) {
                state.kilometresFrom = kilometres;
            },
            setKilometresTo(state, kilometres) {
                state.kilometresTo = kilometres;
            },

            setEngineSizes(state, engineSizes) {
                state.engineSizes = engineSizes;
            },
            setEngineSizesFrom(state, engineSizes) {
                state.engineSizesFrom = engineSizes;
            },
            setEngineSizesTo(state, engineSizes) {
                state.engineSizesTo = engineSizes;
            },

            setTransmissionTypes(state, transmissionTypes) {
                state.transmissionTypes = transmissionTypes;
            },

            setFuelTypes(state, fuelTypes) {
                state.fuelTypes = fuelTypes;
            },

            setBodyTypes(state, bodyTypes) {
                state.bodyTypes = bodyTypes;
            },

            setColours(state, colours) {
                state.colours = colours;
            },

            setSeats(state, seats) {
                state.seats = seats;
            },

            setDoors(state, doors) {
                state.doors = doors;
            },

            setSafetyRatings(state, safetyRatings) {
                state.safetyRatings = safetyRatings;
            },

            setFuelConsumptions(state, fuelConsumptions) {
                state.fuelConsumption = fuelConsumptions;
            },

            setEnvironmentals(state, environmentals) {
                state.environmental = environmentals;
            },

            setEnginePowers(state, enginePowers) {
                state.enginePowers = enginePowers;
            },

            setAccelerations(state, accelerations) {
                state.accelerations = accelerations;
            },

            setSearchResults(state, { slug, item }) {
                // state.searchResults = results;
                Vue.set(state.searchResults, slug, item)
            },

            setSearch (state, { slug, item }) {
                Vue.set(state.search, slug, item)
            },

            setBackendPagination(state, backendPaginaton) {
                state.backendPagination = backendPaginaton;
            },

            setCurrentPage(state, currentPage) {
                state.currentPage = currentPage;
            },

            setCurrentPageName(state, name) {
                state.currentPageName = name;
            },

            setFrom(state, from) {
                state.from = from;
            },

            setTo(state, to) {
                state.to = to;
            },

            setLastPage(state, lastPage) {
                state.lastPage = lastPage;
            },

            setPerPage(state, perPage) {
                state.perPage = perPage;
            },

            setTotal(state, total) {
                state.total = total;
            },

            setResultsLoaded(state, val) {
                state.resultsLoaded = val;
            },

            setSearchOrderBy(state, orderBy) {
                state.searchOrderBy = orderBy;
            },

            setManualSortBy(state, manualSortBy){
                state.manualSortBy = manualSortBy;
            },

            setRequestedPage(state, page) {
                state.requestedPage = page;
            },

            setPagination(state, pagination) {
                state.pagination = pagination;
            },

            setSearchFilters(state, filters) {
                state.searchFilters = filters;
            },
            setJustLoaded(state, value) {
                state.justLoaded = value;
            },
            setUrlParams(state, urlParams) {
                state.urlParams = urlParams;
            },
            setUrl(state, url) {
                state.url = url;
            },
            setIsLoading(state, isLoading) {
                state.isLoading = isLoading;
            },
            setMonthlyPrices(state, monthlyPrices) {
                state.monthlyPrices = monthlyPrices;
            },
            setMonthlyPricesFrom(state, monthlyPrices) {
                state.monthlyPricesFrom = monthlyPrices;
            },
            setMonthlyPricesTo(state, monthlyPrices) {
                state.monthlyPricesTo = monthlyPrices;
            },
            setMonthlyPriceSwitch(state, monthlyPriceSwitch) {
                state.monthlyPriceSwitch = monthlyPriceSwitch;
            },
            setMileageSwitch(state, mileageSwitch) {
                state.mileageSwitch = mileageSwitch;
            },
            setDealer(state, dealer) {
                state.dealer = dealer;
            },
            setInternalSource(state, internalSource) {
                state.s = internalSource;
            },
            setDealerGroup(state, group){
                state.g = group;
            },
            setMapStartLocation(state, mapStartLocation) {
                state.mapStartLocation = mapStartLocation;
            },
            resetSearchVars(state) {
                state.searchMakeDesktop = [];
                state.searchModelDesktop = [];
                state.searchLocations = [];
                state.searchPriceFrom = null;
                state.searchPriceTo = null;
                state.searchMonthlyPricesFrom = null;
                state.searchMonthlyPricesTo = null;
                state.searchFinanceDeposit = null;
                state.searchYearFrom = null;
                state.searchYearTo = null;
                state.searchMileageFrom = null;
                state.searchMileageTo = null;
                state.searchEngineSizeFrom = null;
                state.searchEngineSizeTo = null;
                state.searchTransmissionType = [];
                state.searchFuelType = [];
                state.searchBodyType = [];
                state.searchColour = [];
                state.searchSeats = [];
                state.searchDoors = [];
                state.searchSafetyRating = [];
                state.searchFuelConsumption = [];
                state.searchEnvironmental = [];
                state.searchEnginePower = [];
                state.searchAcceleration = [];
                state.freeText = null;
                state.freeTextAny = false;
                state.s = null;
                state.g = null;
                state.searchResults = {};
            }
        },
        actions: {
            setListViewModeAction({commit}, value){
              commit('setListViewMode', value);
            },
            setSearchMakeAction({commit}, value) {
                commit('setSearchMakeDesktop', value);
            },
            setSearchModelAction({commit}, value) {
                commit('setSearchModelDesktop', value);
            },
            updateUrlAction(context) {

                let url = process.env.VUE_APP_API_URL + '/api/ad-elastic-filters?';
                let urlParams = {};

                if (context.state.searchOrderBy.length > 0 ) {
                    if(context.state.manualSortBy == false){
                        if(context.state.searchOrderBy != 'price-desc'){
                            url += '&sortBy=' + context.state.searchOrderBy;
                            urlParams.sortby = context.state.searchOrderBy;
                        }
                    }else{
                        url += '&sortBy=' + context.state.searchOrderBy;
                        urlParams.sortby = context.state.searchOrderBy;
                    }
                }

                if (context.state.searchMakeDesktop.length != 0) {
                    let make = context.state.searchMakeDesktop.filter(make => make != 'null').join(',');
                    if(make.length > 0) {
                        url += '&makes=' + context.state.searchMakeDesktop.join(',');
                        urlParams.makes = context.state.searchMakeDesktop.filter(make => make != 'null').join('-');
                    }
                }

                if (context.state.searchModelDesktop.length != 0) {
                    let model = context.state.searchModelDesktop.filter(model => model != 'null').join(',');
                    if(model.length > 0){
                        url += '&models=' + model;
                        urlParams.models = context.state.searchModelDesktop.filter(model => model != 'null').map((myModel) => {
                            return myModel;
                        }).join('-');
                    }
                }

                if (context.state.searchYearFrom != null) {
                    url += '&yearFrom=' + context.state.searchYearFrom;
                    urlParams.yearfrom = context.state.searchYearFrom;
                }
                if (context.state.searchYearTo != null) {
                    url += '&yearTo=' + context.state.searchYearTo;
                    urlParams.yearto = context.state.searchYearTo;
                }

                if (context.state.searchPriceFrom != null) {
                    url += '&priceFrom=' + context.state.searchPriceFrom;
                    urlParams.pricefrom = context.state.searchPriceFrom;
                    context.state.monthlyPriceSwitch = false;
                }
                if (context.state.searchPriceTo != null) {
                    url += '&priceTo=' + context.state.searchPriceTo;
                    urlParams.priceto = context.state.searchPriceTo;
                    context.state.monthlyPriceSwitch = false;
                }

                if (context.state.searchFinanceDeposit != null) {
                    url += '&financeDeposit=' + context.state.searchFinanceDeposit;
                    urlParams.financedeposit = context.state.searchFinanceDeposit;
                    context.state.monthlyPriceSwitch = true;
                }

                if (context.state.searchLocations.length != 0) {
                    url += '&locations=' + context.state.searchLocations.join(',');
                    urlParams.locations = context.state.searchLocations.join('-');
                }

                if (context.state.searchMileageFrom != null) {
                    url += '&mileageFrom=' + context.state.searchMileageFrom;
                    urlParams.mileagefrom = context.state.searchMileageFrom;
                    context.state.mileageSwitch = 'mile';
                }
                if (context.state.searchMileageTo != null) {
                    url += '&mileageTo=' + context.state.searchMileageTo;
                    urlParams.mileageto = context.state.searchMileageTo;
                    context.state.mileageSwitch = 'mile';
                }

                if (context.state.searchKilometresFrom != null) {
                    url += '&kilometresFrom=' + context.state.searchKilometresFrom;
                    urlParams.kilometresfrom = context.state.searchKilometresFrom;
                    context.state.mileageSwitch = 'kilometre';
                }
                if (context.state.searchKilometresTo != null) {
                    url += '&kilometresTo=' + context.state.searchKilometresTo;
                    urlParams.kilometresto = context.state.searchKilometresTo;
                    context.state.mileageSwitch = 'kilometre';
                }

                if (context.state.searchMonthlyPricesFrom != null) {
                    url += '&monthlyPriceFrom=' + context.state.searchMonthlyPricesFrom;
                    urlParams.monthlypricefrom = context.state.searchMonthlyPricesFrom;
                    context.state.monthlyPriceSwitch = true;
                }
                if (context.state.searchMonthlyPricesTo != null) {
                    url += '&monthlyPriceTo=' + context.state.searchMonthlyPricesTo;
                    urlParams.monthlypriceto = context.state.searchMonthlyPricesTo;
                    context.state.monthlyPriceSwitch = true;
                }

                if (context.state.searchEngineSizeFrom != null) {
                    url += '&engineSizeFrom=' + context.state.searchEngineSizeFrom;
                    urlParams.enginesizefrom = context.state.searchEngineSizeFrom;
                }
                if (context.state.searchEngineSizeTo != null) {
                    url += '&engineSizeTo=' + context.state.searchEngineSizeTo;
                    urlParams.enginesizeto = context.state.searchEngineSizeTo;
                }

                if (context.state.searchTransmissionType.length != 0) {
                    url += '&transmissionTypes=' + context.state.searchTransmissionType.join(',');
                    urlParams.transmissiontypes = context.state.searchTransmissionType.join('-');
                }

                if (context.state.searchFuelType.length != 0) {
                    url += '&fuelTypes=' + context.state.searchFuelType.join(',');
                    urlParams.fueltypes = context.state.searchFuelType.join('-');
                }

                if (context.state.searchBodyType.length != 0) {
                    url += '&bodyTypes=' + context.state.searchBodyType.join(',');
                    urlParams.bodytypes = context.state.searchBodyType.join('-');
                }

                if (context.state.searchColour.length != 0) {
                    url += '&colours=' + context.state.searchColour.join(',');
                    urlParams.colours = context.state.searchColour.join('-');
                }

                if (context.state.searchSeats.length != 0) {
                    url += '&seats=' + context.state.searchSeats.join(',');
                    urlParams.seats = context.state.searchSeats.join('-');
                }

                if (context.state.searchDoors.length != 0) {
                    url += '&doors=' + context.state.searchDoors.join(',');
                    urlParams.doors = context.state.searchDoors.join('-');
                }

                if (context.state.searchSafetyRating.length != 0) {
                    url += '&safetyRatings=' + context.state.searchSafetyRating.join(',');
                    urlParams.safetyratings = context.state.searchSafetyRating.join('-');
                }

                if (context.state.searchFuelConsumption.length != 0) {
                    url += '&fuelConsumption=' + context.state.searchFuelConsumption.join(',');
                    urlParams.fuelconsumption = context.state.searchFuelConsumption.join('-');
                }

                if (context.state.searchEnvironmental.length != 0) {
                    url += '&environmental=' + context.state.searchEnvironmental.join(',');
                    urlParams.environmental = context.state.searchEnvironmental.join('-');
                }

                if (context.state.searchEnginePower.length != 0) {
                    url += '&enginePowers=' + context.state.searchEnginePower.join(',');
                    urlParams.enginepowers = context.state.searchEnginePower.join('-');
                }

                if (context.state.searchAcceleration.length != 0) {
                    url += '&accelerations=' + context.state.searchAcceleration.join(',');
                    urlParams.accelerations = context.state.searchAcceleration.join('-');
                }

                if (context.state.requestedPage > 1) {
                    url += '&page=' + context.state.requestedPage;
                    urlParams.page = context.state.requestedPage;
                }

                // Internal source
                if (context.state.s != null) {
                    urlParams.s = '1';
                }

                // Internal source
                if (context.state.g != null) {
                    url += '&g=1';
                    urlParams.g = '1';
                }

                if (context.state.freeTextAny == 'true') {
                    url += '&freeTextAny=' + context.state.freeTextAny;
                    urlParams.freetextany = context.state.freeTextAny;
                }

                //Free Text
                if (context.state.freeText != null) {
                    url += '&freeText=' + context.state.freeText;
                    urlParams.freetext = context.state.freeText;
                }

                let urlParamsAux = { ...urlParams };
                delete  urlParamsAux.s;
                delete  urlParamsAux.sortby;
                delete urlParamsAux.page;
                let paramsSize = Object.keys(urlParamsAux).length;

                if(context.state.manualSortBy == false){
                    if(paramsSize == 0 ) {
                        context.state.searchOrderBy = 'price-desc';
                    }
                }

                context.state.url = url;
                context.state.urlParams = urlParams;

                // Email Alert show / hide.
                let aux = JSON.parse(JSON.stringify(urlParams));
                delete aux.sortBy;
                delete aux.page;
                delete aux.s;

                if (Object.keys(aux).length > 0) {
                    context.state.showEmailAlert = true;
                } else {
                    context.state.showEmailAlert = false;
                }
            },

            fetchSearchDealer({ commit }, slug){
                return fetchSearchResults(slug).then(item => {
                    if(item){
                        commit('setSearchResults', {slug, item})
                        commit('setBackendPagination', item.pagination)
                        commit('setCurrentPage', item.pagination.currentPage)
                        commit('setPerPage', item.pagination.perPage)
                        commit('setTotal', item.hits.total.value)
                        commit('setFrom', item.pagination.from)
                        commit('setTo', item.pagination.to)
                        commit('setIsLoading', false)
                        commit('setResultsLoaded', true)
                    }
                });
            },

            fetchSearchShortlist({ commit }, slug){
                return fetchSearchResults(slug).then(item => {
                    if(item){
                        commit('setSearchResults', {slug, item})
                        commit('setBackendPagination', item.pagination)
                        commit('setCurrentPage', item.pagination.currentPage)
                        commit('setPerPage', item.pagination.perPage)
                        commit('setTotal', item.hits.total.value)
                        commit('setFrom', item.pagination.from)
                        commit('setTo', item.pagination.to)
                        commit('setIsLoading', false)
                        commit('setResultsLoaded', true)
                    }
                });
            },

            fetchQuickSearch({ commit }, slug){
                commit('setIsLoading', false)
                return fetchSearchResults(slug).then(item => {
                    commit('setSearchResults', {slug, item})
                });
            },

            fetchSearchResults ({ commit, state, dispatch }, slug) {
                // return the Promise via `store.dispatch()` so that we know
                // when the data has been fetched
                state.url = process.env.VUE_APP_API_URL + '/api/ad-elastic-filters?';

                this.urlParams = slug;
                let objParams = {};
                let arrUrlParams = [];
                if (this.urlParams != null) {
                    arrUrlParams = this.urlParams.split('/');
                    arrUrlParams.forEach((val) => {
                        let arr = val.split('-');
                        let values = val.split(/-(.+)/)[1]
                        objParams[arr[0]] = values;
                    });
                }

                if (objParams.makes != null) {
                    commit('setSearchMakeDesktop', objParams.makes.split('-'));
                } else {
                    commit('setSearchMakeDesktop', []);
                }

                if (objParams.models != null) {
                    commit('setSearchModelDesktop', objParams.models.split('-'));
                } else {
                    commit('setSearchModelDesktop', []);
                }

                if (objParams.yearfrom != null) {
                    commit('setSearchYearFrom', objParams.yearfrom );
                } else {
                    commit('setSearchYearFrom', null);
                }

                if (objParams.yearto != null) {
                    commit('setSearchYearTo', objParams.yearto);
                } else {
                    commit('setSearchYearTo', null);
                }

                if (objParams.pricefrom != null) {
                    commit('setSearchPriceFrom', objParams.pricefrom);
                    commit('setMonthlyPriceSwitch', false);
                } else {
                    commit('setSearchPriceFrom', null);
                }

                if (objParams.priceto != null) {
                    commit('setSearchPriceTo', objParams.priceto);
                    commit('setMonthlyPriceSwitch', false);
                } else {
                    commit('setSearchPriceTo', null);
                }

                if (objParams.financedeposit != null) {
                    commit('setSearchFinanceDeposit', objParams.financedeposit);
                    commit('setMonthlyPriceSwitch', true);
                } else {
                    commit('setSearchFinanceDeposit', null);
                }

                if (objParams.locations != null) {
                    commit('setSearchLocations', objParams.locations.split('-'));
                } else {
                    commit('setSearchLocations', []);
                }

                if (objParams.mileagefrom != null) {
                    commit('setSearchMileageFrom', objParams.mileagefrom);
                    commit('setMileageSwitch', 'mile');
                } else {
                    commit('setSearchMileageFrom', null);
                }

                if (objParams.mileageto != null) {
                    commit('setSearchMileageTo', objParams.mileageto);
                    commit('setMileageSwitch', 'mile');
                } else {
                    commit('setSearchMileageTo', null);
                }

                if (objParams.kilometresfrom != null) {
                    commit('setSearchKilometresFrom', objParams.kilometresfrom);
                    commit('setMileageSwitch', 'kilometre');
                } else {
                    commit('setSearchKilometresFrom', null);
                }

                if (objParams.kilometresto != null) {
                    commit('setSearchKilometresTo', objParams.kilometresto);
                    commit('setMileageSwitch', 'kilometre');
                } else {
                    commit('setSearchKilometresTo', null);
                }

                if (objParams.monthlypricefrom != null) {
                    commit('setSearchMonthlyPricesFrom', objParams.monthlypricefrom);
                    commit('setMonthlyPriceSwitch',  true);
                } else {
                    commit('setSearchMonthlyPricesFrom', null);
                }

                if (objParams.monthlypriceto != null) {
                    commit('setSearchMonthlyPricesTo', objParams.monthlypriceto);
                    commit('setMonthlyPriceSwitch',  true);
                } else {
                    commit('setSearchMonthlyPricesTo', null);
                }

                if (objParams.enginesizefrom != null) {
                    commit('setSearchEngineSizeFrom', objParams.enginesizefrom);
                } else {
                    commit('setSearchEngineSizeFrom', null);
                }

                if (objParams.enginesizeto != null) {
                    commit('setSearchEngineSizeTo', objParams.enginesizeto);
                } else {
                    commit('setSearchEngineSizeTo', null);
                }

                if (objParams.transmissiontypes != null) {
                    commit('setSearchTransmissionType', objParams.transmissiontypes.split('-'));
                } else {
                    commit('setSearchTransmissionType', []);
                }

                if (objParams.fueltypes != null) {
                    commit('setSearchFuelType', objParams.fueltypes.split('-'));
                } else {
                    commit('setSearchFuelType', []);
                }

                if (objParams.bodytypes != null) {
                    commit('setSearchBodyType', objParams.bodytypes.split('-'));
                } else {
                    commit('setSearchBodyType', []);
                }

                if (objParams.colours != null) {
                    commit('setSearchColour', objParams.colours.split('-'));
                } else {
                    commit('setSearchColour', []);
                }

                if (objParams.seats != null) {
                    commit('setSearchSeats', objParams.seats.split('-'));
                } else {
                    commit('setSearchSeats', []);
                }

                if (objParams.doors != null) {
                    commit('setSearchDoors', objParams.doors.split('-'));
                } else {
                    commit('setSearchDoors', []);
                }

                if (objParams.safetyratings != null) {
                    commit('setSearchSafetyRating', objParams.safetyratings.split('-'));
                } else {
                    commit('setSearchSafetyRating', []);
                }

                if (objParams.fuelconsumption != null) {
                    commit('setSearchFuelConsumption', objParams.fuelconsumption.split('-'));
                } else {
                    commit('setSearchFuelConsumption', []);
                }

                if (objParams.environmental != null) {
                    commit('setSearchEnvironmental', objParams.environmental.split('-'));
                } else {
                    commit('setSearchEnvironmental', []);
                }

                if (objParams.enginepowers != null) {
                    commit('setSearchEnginePower', objParams.enginepowers.split('-'));
                } else {
                    commit('setSearchEnginePower', []);
                }

                if (objParams.accelerations != null) {
                    commit('setSearchAcceleration', objParams.accelerations.split('-'));
                } else {
                    commit('setSearchAcceleration', []);
                }

                if (objParams.freetext != null )  {
                    commit('setFreeText', objParams.freetext);
                } else {
                   commit('setFreeText', null);
                }

                if (objParams.freetextany != null )  {
                    commit('setFreeTextAny', objParams.freetextany);
                } else {
                    commit('setFreeTextAny', null);
                }

                if (objParams.sortby != null) {
                    commit('setSearchOrderBy', objParams.sortby);
                } else {
                    commit('setSearchOrderBy', 'price-desc');
                }

                if (objParams.page != null) {
                    commit('setRequestedPage', objParams.page);
                } else {
                    commit('setRequestedPage', 1);
                }

                // Internal source
                if (objParams.s != null) {
                    commit('setInternalSource', objParams.s);
                } else {
                    commit('setInternalSource', null);
                }

                // Dealer Group
                if (objParams.g != null) {
                    commit('setDealerGroup', objParams.g);
                } else {
                    commit('setDealerGroup', null);
                }

                //Cookie Dealer Group
                let myCookie = function (name) {
                    if(typeof document == 'undefined') return false;
                    let value = `; ${document.cookie}`;
                    let parts = value.split(`; ${name}=`);
                    if (parts.length === 2) return parts.pop().split(';').shift();
                }

                if(typeof document !== 'undefined' && myCookie("g") == 1){
                    commit('setDealerGroup', 1);
                }

                checkFriendlyUrls(commit, slug);

                dispatch('updateUrlAction');
                commit('setIsLoading', true);

                return fetchSearchResults(state.url).then(item =>   {

                    if(!item){
                        return false;
                    }

                    // Set meta information on the client side
                    if(typeof document !== 'undefined'){
                        if(item.seo.title){
                            document.title = item.seo.title;
                        }

                        document.querySelector('meta[name="description"]').setAttribute("content", item.seo.description);
                        document.querySelector('meta[name="keywords"]').setAttribute("content", item.seo.keywords);
                        document.querySelector('meta[name="robots"]').setAttribute("content", 'all');

                        let myCookie = function (name) {
                            let value = `; ${document.cookie}`;
                            let parts = value.split(`; ${name}=`);
                            if (parts.length === 2) return parts.pop().split(';').shift();
                        }

                        if(myCookie("g") == 1){
                           commit('setDealerGroup', 1);
                        }
                    }

                    commit('setSearchResults', { slug, item })
                    commit('setBackendPagination', item.pagination )
                    commit('setCurrentPage', item.pagination.currentPage )
                    commit('setPerPage', item.pagination.perPage )
                    commit('setTotal', item.hits.total.value )
                    commit('setFrom', item.pagination.from )
                    commit('setTo', item.pagination.to )
                    commit('setIsLoading', false )
                    commit('setResultsLoaded', true)

                    /**
                     *  Makes
                     **/
                    if (state.currentFilter != 'makes' || state.searchMakeDesktop.length == 0) {
                        let arrMap = [];
                        if(myCookie("g") == 1 || state.g == 1){
                            arrMap = item.unfiltered.makes.buckets[0].makes.buckets;386
                        }else{
                            arrMap = item.unfiltered.makes.buckets
                        }

                        let filter = arrMap.map(element => {
                            const container = {
                                id: stringToSlug(element.key),
                                label: element.key + ' (' + element.doc_count + ')',
                                labelDesktop: element.key,
                                docCount: element.doc_count,
                                customLabel: element.key + ' (' + element.doc_count + ')'
                            };
                            return container;
                        });


                        let filterMake = filter.map(element => {
                            item.makes.map(make => {
                                if (stringToSlug(make.key) == element.id || state.searchMakeDesktop.filter(search => stringToSlug(search) == element.id).length > 0) {
                                    element.enabled = false;
                                    element.docCount = make.doc_count != null ? make.doc_count : 0;
                                    element.customLabel = make.key + ' (' + make.doc_count + ')';
                                }
                            });
                            return element;
                        });
                        commit('setMakes', filterMake);
                    }

                    /**
                     *  Models
                     **/
                    if ((state.currentFilter != 'models' && state.searchMakeDesktop.length > 0) || (state.searchModelDesktop.length == 0 && state.searchMakeDesktop.length > 0)) {
                        let filter = item.make_models.map(element => ({
                            id: stringToSlug(element.key).substring(stringToSlug(element.key).indexOf('+') + 1),
                            name: stringToSlug(element.key).substring(stringToSlug(element.key).indexOf('+') + 1),
                            label: element.key.replace('_', ' ') + ' (' + element.doc_count + ')',
                            labelDesktop: element.key,
                            docCount: element.doc_count,
                            customLabel: element.key + ' (' + element.doc_count + ')'
                        }));
                        commit('setMakeModels', filter);
                    } else if (state.searchMakeDesktop.length == 0) {
                        commit('setMakeModels', null);
                    }


                    /**
                     *  Years
                     **/
                    let filterYear = item.years.map(element => ({
                        id: element.key,
                        label: element.key,
                        customLabel: element.key,
                        docCount: element.doc_count,
                    }));

                    if (state.searchYearFrom != null) {
                        commit('setYearsTo', filterYear.filter(element => element.id >= state.searchYearFrom));
                    } else {
                        commit('setYearsTo', filterYear);
                    }

                    if (state.searchYearTo != null) {
                        commit('setYearsFrom', filterYear.filter(element => element.id <= state.searchYearTo));
                    } else {
                        commit('setYearsFrom', filterYear);
                    }


                    /**
                     *  Prices
                     **/
                    let filterPrice = item.prices.map(element => ({
                        id: element.key,
                        label: numeral(element.key).format("0,000"),
                        customLabel: element.key,
                    }));

                    if (state.searchPriceFrom != null) {
                        commit('setPricesTo', filterPrice.filter(element => parseInt(element.id) >= parseInt(state.searchPriceFrom)));
                    } else {
                        commit('setPricesTo', filterPrice);
                    }

                    if (state.searchPriceTo != null) {
                        commit('setPricesFrom', filterPrice.filter(element => parseInt(element.id) <= parseInt(state.searchPriceTo)));
                    } else {
                        commit('setPricesFrom', filterPrice);
                    }

                    /**
                     *  Miles
                     **/
                    let filterMiles = item.mileages.map(element => ({
                        id: element.key,
                        label: numeral(element.key).format("0,000") + ' miles',
                        customLabel: element.key
                    }));

                    if (state.searchMileageFrom != null) {
                        commit('setMileagesTo', filterMiles.filter(element => parseInt(element.id) >= parseInt(state.searchMileageFrom)));
                    } else {
                        commit('setMileagesTo', filterMiles);
                    }

                    if (state.searchMileageTo != null) {
                        commit('setMileagesFrom', filterMiles.filter(element => parseInt(element.id) <= parseInt(state.searchMileageTo)));
                    } else {
                        commit('setMileagesFrom', filterMiles);
                    }


                    /**
                     *  Kilometres
                     **/
                    let filterKilometres = item.kilometres.map(element => ({
                        id: element.key,
                        label: numeral(element.key).format("0,000") + ' km',
                        customLabel: element.key
                    }));

                    if (state.searchKilometresFrom != null) {
                        commit('setKilometresTo', filterKilometres.filter(element => parseInt(element.id) >= parseInt(state.searchKilometresFrom)));
                    } else {
                        commit('setKilometresTo', filterKilometres);
                    }

                    if (state.searchKilometresTo != null) {
                        commit('setKilometresFrom', filterKilometres.filter(element => parseInt(element.id) <= parseInt(state.searchKilometresTo)));
                    } else {
                        commit('setKilometresFrom', filterKilometres);
                    }


                    /**
                     *  Engine Sizes
                     **/
                    let filterEngineSize = item.engine_sizes.map(element => ({
                        id: element.key,
                        label: element.key + ' L',
                        customLabel: element.key
                    }));

                    if (state.searchEngineSizeFrom != null) {
                        commit('setEngineSizesTo', filterEngineSize.filter(element => parseFloat(element.id) >= parseFloat(state.searchEngineSizeFrom)));
                    } else {
                        commit('setEngineSizesTo', filterEngineSize);
                    }

                    if (state.searchEngineSizeTo != null) {
                        commit('setEngineSizesFrom', filterEngineSize.filter(element => parseFloat(element.id) <= parseFloat(state.searchEngineSizeTo)));
                    } else {
                        commit('setEngineSizesFrom', filterEngineSize);
                    }

                    /**
                     *  Transmission Types ( Gearbox ).
                     **/
                    if (state.currentFilter != 'gearbox' || state.searchTransmissionType.length == 0) {
                        let filterTransmissionTypes = item.transmission_types.map(element => ({
                            id: stringToSlug(element.key),
                            label: element.key + ' (' + element.doc_count + ')',
                            labelDesktop: element.key,
                            customLabel: element.key + ' (' + element.doc_count + ')',
                            docCount: element.doc_count
                        }));
                        commit('setTransmissionTypes', filterTransmissionTypes);
                    }



                    /**
                     * Fuel Types
                     **/
                    if (state.currentFilter != 'fuelType' || state.searchFuelType.length == 0) {
                        let filterFuelTypes = item.fuel_types.map(element => ({
                            id: stringToSlug(element.key),
                            label: element.key.charAt(0).toUpperCase() + element.key.slice(1) + ' (' + element.doc_count + ')',
                            labelDesktop: element.key.charAt(0).toUpperCase() + element.key.slice(1),
                            customLabel: element.key.charAt(0).toUpperCase() + element.key.slice(1) + ' (' + element.doc_count + ')',
                            docCount: element.doc_count
                        }));
                        commit('setFuelTypes', filterFuelTypes);
                    }


                    /**
                     * Body Types
                     **/
                    if (state.currentFilter != 'bodyType' || state.searchBodyType.length == 0) {
                        let filterBodyTypes = item.body_types.map(element => ({
                            id: stringToSlug(element.key),
                            label: element.key.charAt(0).toUpperCase() + element.key.slice(1) + ' (' + element.doc_count + ')',
                            labelDesktop: element.key.charAt(0).toUpperCase() + element.key.slice(1),
                            customLabel: element.key.charAt(0).toUpperCase() + element.key.slice(1) + ' (' + element.doc_count + ')',
                            docCount: element.doc_count
                        }));
                        commit('setBodyTypes', filterBodyTypes);
                    }


                    /**
                     * Colours
                     **/
                    if (state.currentFilter != 'colour' || state.searchColour.length == 0) {
                        let filterColours = item.colours.map(element => ({
                            id: stringToSlug(element.key),
                            label: element.key.charAt(0).toUpperCase() + element.key.slice(1) + ' (' + element.doc_count + ')',
                            labelDesktop: element.key.charAt(0).toUpperCase() + element.key.slice(1),
                            customLabel: element.key.charAt(0).toUpperCase() + element.key.slice(1) + ' (' + element.doc_count + ')',
                            docCount: element.doc_count
                        }));
                        commit('setColours', filterColours);
                    }


                    /**
                     * Seats
                     **/
                    if (state.currentFilter != 'seats' || state.searchSeats.length == 0) {
                        let filterSeats = item.seats.map(element => ({
                            id: element.key,
                            label: element.key + ' Seats (' + element.doc_count + ')',
                            labelDesktop: element.key + ' Seats',
                            customLabel: element.key + ' Seats (' + element.doc_count + ')',
                            docCount: element.doc_count
                        }));
                        commit('setSeats', filterSeats);
                    }


                    /**
                     * Doors
                     **/
                    if (state.currentFilter != 'doors' || state.searchDoors.length == 0) {
                        let filterDoors = item.doors.map(element => ({
                            id: element.key,
                            label: element.key + ' Doors (' + element.doc_count + ')',
                            labelDesktop: element.key + ' Doors',
                            customLabel: element.key + ' Doors (' + element.doc_count + ')',
                            docCount: element.doc_count
                        }));
                        commit('setDoors', filterDoors);
                    }


                    /**
                     * Safety Ratings
                     **/
                    if (state.currentFilter != 'safetyRatings' || state.searchSafetyRating.length == 0) {
                        let filterSafetyRatings = item.safety_ratings.filter(element => element.doc_count > 0).map(element => ({
                            id: element.key.toString().replace('+ Star', '').replace(' Star', ''),
                            label: element.key + ' (' + element.doc_count + ')',
                            labelDesktop: element.key,
                            customLabel: element.key + ' (' + element.doc_count + ')',
                            docCount: element.doc_count
                        }));
                        commit('setSafetyRatings', filterSafetyRatings);
                    }


                    /**
                     * Fuel Consumption
                     **/
                    if (state.currentFilter != 'fuelConsumption' || state.searchFuelConsumption.length == 0) {
                        let filterFuelConsumptions = item.fuel_consumptions.filter(element => element.doc_count > 0).map(element => ({
                            id: element.key.toString().replace('+ mpg', '').replace('Less than 30 mpg', '0'),
                            label: element.key + ' (' + element.doc_count + ')',
                            labelDesktop: element.key,
                            customLabel: element.key + ' (' + element.doc_count + ')',
                            docCount: element.doc_count
                        }));
                        commit('setFuelConsumptions', filterFuelConsumptions);
                    }


                    /**
                     * Environmental
                     **/
                    if (state.currentFilter != 'environmental' || state.searchEnvironmental.length == 0) {
                        let filterEnviromental = item.environmental.map(element => ({
                            id: element.key.toString().replace('+', ''),
                            label: element.key + ' Star (' + element.doc_count + ')',
                            labelDesktop: element.key + ' Star',
                            customLabel: element.key + ' Star (' + element.doc_count + ')',
                            docCount: element.doc_count
                        }));
                        commit('setEnvironmentals', filterEnviromental);
                    }



                    /**
                     * Engine Powers
                     **/
                    if (state.currentFilter != 'enginePowers' || state.searchEnginePower.length == 0) {
                        let filterEnginePowers = item.engine_powers.filter(element => element.doc_count > 0).map(element => ({
                            id: element.key.replace('_', '+'),
                            label: element.key.toString().replace('_', '-').replace(/\.0/g, '').replace(/-\*/g, '+') + ' (' + element.doc_count + ')',
                            labelDesktop: element.key.toString().replace('_', '-').replace(/\.0/g, '').replace(/-\*/g, '+') + ' BHP',
                            customLabel: element.key.toString().replace('_', '-') + ' BHP (' + element.doc_count + ')',
                            docCount: element.doc_count
                        }));
                        commit('setEnginePowers', filterEnginePowers);
                    }

                    /**
                     * Accelerations
                     **/
                    if (state.currentFilter != 'accelerations' || state.searchAcceleration.length == 0) {
                        let filterAccelerations = item.acceleration.filter(element => element.doc_count > 0).map(element => ({
                            id: element.key.replace('s+', '').replace('s','').replace('_', '+'),
                            label: element.key.toString().replace('_', '-') + ' (' + element.doc_count + ')',
                            labelDesktop: element.key.toString().replace('_', '-') + ' (0-60mph)',
                            customLabel: element.key.toString().replace('_', '-') + ' (0-60mph) (' + element.doc_count + ')',
                            docCount: element.doc_count
                        }));
                        commit('setAccelerations', filterAccelerations);
                    }

                    /**
                     * Locations
                     **/
                    if (state.currentFilter != 'locations' || state.searchLocations.length == 0) {
                        commit('setLocations', item.provinces);
                    }

                    /**
                     * Counties
                     **/
                    if (state.currentFilter != 'locations' || state.searchLocations.length == 0) {
                        let filterLocations = item.counties.map(element => ({
                            id: element.key,
                            label: element.key.charAt(0).toUpperCase() + element.key.slice(1),
                            docCount: element.doc_count,
                            customLabel: element.key.charAt(0).toUpperCase() + element.key.slice(1) + ' (' + element.doc_count + ')'
                        }));
                        commit('setCounties', filterLocations);
                    }

                    /**
                     * Finance Deposits
                     **/
                    let filterFinanceDeposits = item.finance_options.filter(element => element.doc_count > 0).map(element => ({
                        id: element.key,
                        label: numeral(element.key).format("0,000"),
                        customLabel: element.key
                    }));
                    commit('setFinanceDeposits', filterFinanceDeposits);

                    /**
                     * Monthly Prices
                     **/
                    let filterMonthlyPrice = item.monthly_prices.map(element => ({
                        id: element.key,
                        label: numeral(element.key).format("0,000"),
                        customLabel: element.key
                    }));

                    if (state.searchMonthlyPricesFrom != null) {
                        let res = filterMonthlyPrice.filter(element => parseInt(element.id) >= parseInt(state.searchMonthlyPricesFrom));
                        commit('setMonthlyPricesTo', res);
                    } else {
                        commit('setMonthlyPricesTo', filterMonthlyPrice);
                    }

                    if (state.searchMonthlyPricesTo != null) {
                        commit('setMonthlyPricesFrom', filterMonthlyPrice.filter(element => parseInt(element.id) <= parseInt(state.searchMonthlyPricesTo)));
                    } else {
                        commit('setMonthlyPricesFrom', filterMonthlyPrice);
                    }

                })
            },

        },
        modules: {
            ads,
            dealers,
            reviews,
            industrynews,
            breadcrumbs,
        }
    })
}

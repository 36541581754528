import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
    namespaced: true,
    state: () => ({
        industryNewsArticle: {},
        industryNews: {},
        currentPage: 1,
        from: 0,
        lastPage: 0,
        to: 0,
        perPage: 0,
        total: 0,
        requestedPage: null,
    }),
    getters,
    mutations,
    actions
};

<template>
    <div class="homepage_grid_item homepage_grid_item--category_listings">
        <div class="cats">

            <router-link v-for="(item,key) in categories" v-bind:key="key" :to="{path: item.path}" class="cats__cat" >
                <div class="cats__cat_img">
                    <div class="thumb sprite" :class="item.class" >
                        <img :src="require('../assets/img/car-transparent.png')"  width="500" height="350" :alt="item.name" />
                    </div>
                </div>
                <h2>{{ item.name }}</h2>
            </router-link>

        </div>
    </div>
</template>

<script>
    export default {
        name: "HomeCategories",
        props: ['modifier'],
        data: function () {
            return {
                categories: [
                    {path:'/used-cars/5-star-safety', class:'five-star-safety', name:'5 Star Safety'},
                    {path:'/used-cars/7-seaters', class:'seven-seater', name:'7 Seaters'},
                    {path:'/used-cars/8-plus-seaters', class:'eight-plus-seats', name:'8+ Seaters'},
                    {path:'/used-cars/automatics', class:'automatics', name:'Automatics'},
                    {path:'/used-cars/classic', class:'classic', name:'Classic'},
                    {path:'/used-cars/eco-friendly', class:'eco-friendly', name:'Eco-friendly'},
                    {path:'/used-cars/electric', class:'electric', name:'Electric'},
                    {path:'/used-cars/fast', class:'fast', name:'Fast'},
                    {path:'/used-cars/hybrids', class:'hybrids', name:'Hybrids'},
                    {path:'/used-cars/low-running-costs', class:'low-running-costs', name:'Low Running Costs'},
                    {path:'/used-cars/luxury', class:'luxury', name:'Luxury'},
                    {path:'/used-cars/powerful', class:'powerful', name:'Powerful'},
                    {path:'/used-cars/pretty', class:'pretty', name:'Pretty'},
                    {path:'/used-cars/suv', class:'suvs', name:'SUVs'},
                    {path:'/used-cars/vans', class:'vans', name:'Vans'},
                    {path:'/used-cars/wheelchair', class:'wheelchair', name:'Wheelchair Accessible'},
                ],
            }
        },
    }
</script>

<style scoped>

</style>

import {fetchIndustryNews, fetchIndustryNewsArticle} from "../../api";

export default {
    fetchIndustryNews({commit}, page) {
        return fetchIndustryNews(page).then(item => {
            commit('setIndustryNews', {page, item})
        })
    },

    fetchIndustryNewsArticle({commit}, slug) {
        return fetchIndustryNewsArticle(slug).then(item => {
            commit('setIndustryNewsArticle', {slug, item})
        })
    },


};

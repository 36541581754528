import {fetchReview, fetchReviews, fetchMakeReviews, fetchReviewMakes} from "../../api";

export default {
    fetchReview({commit}, page) {
        return fetchReview(page).then(item => {
            commit('setReview', {page, item})
        })
    },

    fetchReviews({commit}, page) {
        return fetchReviews(page).then(item => {
            commit('setReviews', {page, item})
        })
    },

    fetchMakeReviews({commit}, page) {
        console.log('fetching make', page);
        return fetchMakeReviews(page).then(item => {
            commit('setReviews', {page, item})
        })
    },

    fetchReviewMakes({commit}, page) {
        return fetchReviewMakes(page).then(item => {
            commit('setReviewMakes', {page, item})
        })
    }
};

import Vue from "vue";

export default {
    setIndustryNews(state, {page, item}) {
        Vue.set(state.industryNews, page, item);
    },

    setIndustryNewsArticle(state, {slug, item}) {
        Vue.set(state.industryNewsArticle, slug, item);
    },

    setCurrentPage(state, currentPage) {
        state.currentPage = currentPage;
    },

    setFrom(state, from) {
        state.from = from;
    },

    setTo(state, to) {
        state.to = to;
    },

    setLastPage(state, lastPage) {
        state.lastPage = lastPage;
    },

    setPerPage(state, perPage) {
        state.perPage = perPage;
    },

    setTotal(state, total) {
        state.total = total;
    },
};

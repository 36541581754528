<template>
    <div class="ad_cta" :class="modifier">
        <p>Terrific.ie details used cars for sale in Ireland, we also provide detailed reviews on each car and videos where available. Feedback as to how we can improve our website is very welcome.</p>
        <!--<a href="#">Private advertisers can sell their car here.</a>-->
    </div>
</template>

<script>
    export default {
        name: "AdCta",
        props: ['modifier'],
    }
</script>

<style scoped>

</style>

<template>
  <div>
    <footer class="footer">
      <div class="wrapper">
        <div class="footer__logo"><router-link :to="{name: 'Home' }" alt="Terrific.ie">Terrific.ie</router-link></div>

        <div class="footer__about">
          <div class="topic">About Us</div>
          <p>We believe we are the best used cars website as we uniquely identify cars which we believe are terrific value for money.</p>
          <p><!-- Our algorithm takes into account the price, year, engine size, running costs and 100 other factors to arrive at a score for each car advertised. --></p>
        </div>

        <div class="footer__social">
          <div class="topic">Follow us</div>
          <ul>
            <li><a href="https://www.facebook.com/terrific.ie" target="_blank" rel="noopener">Facebook</a></li>
            <li><a href="https://twitter.com/terrificdotie" target="_blank" rel="noopener">Twitter</a></li>
          </ul>
        </div>

        <div class="footer__links">
          <div class="topic">Quick Links</div>
          <ul>
            <li><router-link :to="{name: 'contact-us' }">Contact terrific.ie</router-link></li>
            <li><router-link :to="{name: 'privacy' }">Privacy &amp; Cookies</router-link></li>
            <li><router-link :to="{name: 'terms' }">Terms</router-link></li>
            <li><router-link :to="{name: 'shortlist' }">Shortlist</router-link></li>
          </ul>
          <small>&copy; Terrific.ie {{ currentYear }}</small>
        </div>
      </div>

    </footer>
    <div class="hotspot"></div>
  </div>
</template>

<script>
export default {
    name: 'MainFooter',
    data() {
        return {
            currentYear: new Date().getFullYear()
        };
    }
}
</script>

<style scoped>
</style>

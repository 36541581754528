import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
    namespaced: true,
    state: () => ({
        ad: {},
        ads: {},
        error404: false,
        make404: '',
        model404: '',
    }),
    getters,
    mutations,
    actions
};

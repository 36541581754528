import Vue from "vue";

export default {
    setAd(state, payload) {
        // Set meta information on the client side
        if(typeof document !== 'undefined'){
            document.title = payload.item && payload.item.seo ? payload.item.seo.title : '';
            document.querySelector('meta[name="description"]').setAttribute("content", payload.item && payload.item.seo ? payload.item.seo.description : '');
            document.querySelector('meta[name="keywords"]').setAttribute("content", payload.item && payload.item.seo ? payload.item.seo.keywords : '');
            document.querySelector('meta[name="robots"]').setAttribute("content",  "all");
        }
        Vue.set(state.ad, payload.slug, payload.item);
    },
    setError404(state, payload) {
       state.error404 = payload;
    },
    setMake404(state, make){
        state.make404 = make;
    },
    setModel404(state, model){
        state.model404 = model;
    },
};

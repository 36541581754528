<template>
    <header class="header print">
        <div class="wrapper">

            <div class="menutoggle"  id="menubutton"  data-id="menubutton" v-on:click="handleMenu">
                <span></span>
                <span></span>
                <span></span>
            </div>

            <div class="header__logo"><router-link :to="{name: 'Home'}" alt="Home Page">Terrific.ie</router-link></div>
            <nav class="header__nav">
                <ul v-on:click="closeMenu">
                    <li><router-link :to="{name: 'Home'}"  alt="home" exact>Home</router-link></li>
                    <li><router-link :to="{path: '/used-cars' }" alt="Used Cars" @click.native="resetPage;$router.push('/used-cars')" data-cy="menu-used-cars">Used Cars</router-link></li>
                    <li><router-link :to="{path: '/car-reviews' }">Car Reviews</router-link></li>
                    <li><router-link :to="{name: 'car-buyers-guide' }">Car Buyers Guide</router-link></li>
                    <li><router-link :to="{name: 'car-finance' }">Car Finance</router-link></li>
                    <li><router-link :to="{name: 'news' }">Industry News</router-link></li>
                    <li><router-link :to="{name: 'advertise-landing' }" data-cy="menu-advertiser">Advertise</router-link></li>
                    <li class="mobile_only"><router-link :to="{name: 'shortlist' }">Shortlist</router-link></li>
                    <li class="mobile_only"><router-link :to="{name: 'contact-us'}">Contact Terrific.ie</router-link></li>
                </ul>
            </nav>
            <!--<a href="#" class="header__adlink">Advertise Your Car</a>-->
            <!--<div class="header__search"><a href="#" data-class="openrefine"></a></div>-->
        </div>
    </header>
</template>

<script>
    import $ from 'jquery'
    export default {
        name: "MainHeader",
        mounted(){
            $('a').click(() => {
                $('body').removeClass('static')
                if( this.getCookie("g") == 1 ) {
                    this.$store.commit('setDealerGroup', 1);
                }
            });
        },
        methods: {
            isActive (str) {
                return this.$route.name == str;
            },
            getCookie (name) {
                let value = `; ${document.cookie}`;
                let parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            },
            resetPage(){
                this.$store.commit('setCurrentPage', 1);
                this.$store.commit('setRequestedPage', 1);
            },
            closeMenu(){
                setTimeout(function(){
                    $('#menubutton').removeClass('open')
                    $(".header__search").removeClass("menuopen")
                    $('.header__nav').removeClass("menuopen")
                    $('body').removeClass('static')
                }, 100);

            },
            handleMenu () {

                if ( $('#menubutton').hasClass('open') ) {
                    this.closeMobileNav()
                } else {
                    this.openMobileNav()
                }
            },
            openMobileNav (){
                $('#menubutton').addClass('open')
                $(".header__search").addClass("menuopen")
                $('.header__nav').addClass("menuopen")
                $('body').addClass('static')
            },
            closeMobileNav(){
                $('#menubutton').removeClass('open')
                $(".header__search").removeClass("menuopen")
                $('.header__nav').removeClass("menuopen")
                $('body').removeClass('static')
            }
        }
    }
</script>

<style scoped>

</style>

import Vue from "vue";

export default {
    setReview(state, {page, item}) {
        // Set meta information on the client side
        if(typeof document !== 'undefined' && item != null){
            document.title = item.seo.title;
            document.querySelector('meta[name="description"]').setAttribute("content", item.seo.description);
            document.querySelector('meta[name="keywords"]').setAttribute("content", item.seo.keywords);
            document.querySelector('meta[name="robots"]').setAttribute("content", "all");
        }

        Vue.set(state.review, page, item);
    },

    setReviews(state, {page, item}) {
        Vue.set(state.reviews, page, item);
    },

    setReviewMakes(state, {page, item}) {
        Vue.set(state.reviewMakes, page, item);
    },

    setCurrentPage(state, currentPage) {
        state.currentPage = currentPage;
    },

    setFrom(state, from) {
        state.from = from;
    },

    setTo(state, to) {
        state.to = to;
    },

    setLastPage(state, lastPage) {
        state.lastPage = lastPage;
    },

    setPerPage(state, perPage) {
        state.perPage = perPage;
    },

    setTotal(state, total) {
        state.total = total;
    },
};

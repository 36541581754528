import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { createRouter } from './router'
import { createStore } from './store'
// import { BootstrapVue } from 'bootstrap-vue'
import VueMeta from 'vue-meta'
import './assets/sass/global.scss'
import VueAnalytics from 'vue-analytics'

axios.defaults.headers.common['Authorization'] = 'Bearer ' +process.env.VUE_APP_API_TOKEN;
Vue.config.productionTip = false
Vue.use(VueAxios, axios)
// Vue.use(BootstrapVue)
Vue.use(VueMeta, {
    keyName: 'metaInfo',
    attribute: 'data-vue-meta',
    ssrAttribute: 'data-vue-meta-server-rendered',
    tagIDKeyName: 'vmid',
    refreshOnceOnNavigation: true
})

Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS
})


Vue.filter('capitalize', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
})

export async function createApp ({
          beforeApp = () => {},
          afterApp = () => {}
        } = {}) {
          const router = createRouter()
          const store = createStore()


          await beforeApp({
            router,
            store,

          })

          const app = new Vue({
  router,
  store,
  render: h => h(App)
})

          const result = {
            app,
            router,
            store,

          }

          await afterApp(result)

          return result
        }

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { page } from 'vue-analytics'

/**
 * This solves the problem:
 * Error: Avoided redundant navigation to current location
 */
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};
Vue.use(VueRouter)
export function createRouter() {
    const routes = [
        {
            path: '/',
            name: 'Home',
            component: Home,
        },
        {
            path: '/about',
            name: 'About',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "About" */ '../views/About.vue')
        },
        {
            path: '/used-cars/:slug(.*/r/.*)',
            name: 'vehicle-detail',
            props: true,
            meta: {
                'vehicle-detail' : {
                    title: 'Vehicle Detail',
                }
            },
            component: () => import(/* webpackChunkName: "vehicle-detail" */ '../views/AdDetail.vue')
        },
        {
            path: '/:slug(\\d+)',
            name: 'vehicle-detail-adv',
            props: true,
            meta: {
                'vehicle-detail-adv' : {
                    title: 'Vehicle Detail',
                }
            },
            component: () => import(/* webpackChunkName: "vehicle-detail" */ '../views/Shortlink.vue')
        },
        {
            path: '/:slug(\\d+)/print',
            name: 'vehicle-detail-adv-print',
            props: true,
            meta: {
                'vehicle-detail-adv-print' : {
                    title: 'Vehicle Detail',
                }
            },
            component: () => import(/* webpackChunkName: "vehicle-detail" */ '../views/AdDetailPrintable.vue')
        },
        {
            path: '/used-cars/:slug(5-star-safety.*|7-seaters.*|8-plus-seaters.*|automatics.*|classic*.|eco-friendly.*|electric.*|fast.*|hybrids.*|low-running-costs.*|luxury.*|powerful.*|pretty.*|suv.*|vans.*|wheelchair.*|makes.*|models.*|yearfrom.*|yearto.*|pricefrom.*|priceto.*|financedeposit.*|monthlypricefrom.*|monthlypriceto.*|locations.*|mileagefrom.*|mileageto.*|kilometresfrom.*|kilometresto.*|enginesizefrom.*|enginesizeto.*|transmissiontypes.*|fueltypes.*|bodytypes.*|colours.*|seats.*|doors.*|safetyratings.*|fuelconsumption.*|environmental.*|enginepowers.*|accelerations.*|freetext.*|page.*|sortby.*|g.*|s.*)?',
            name: 'used-cars',
            props: true,
            meta: {
                'used-cars' : {
                    title: 'Used Cars',
                }
            },
            component: () => import(/* webpackChunkName: "used-cars" */ '../views/UsedCars.vue')
        },
        {
            /**
             * Special route alias for hybrid cars . (Needs to be declared after the used car routes)
             */
            path: '/used-cars/fueltypes-hybrid_unspecified-hybrid_diesel-hybrid_diesel_plug_in-hybrid_petrol-hybrid_petrol_plug_in',
            component: () => import(/* webpackChunkName: "used-cars" */ '../views/UsedCars.vue'),
            alias: '/used-cars/hybrids'
        },

        {
            path: '/ad-detail-printable/:slug(.*/r/.*)',
            name: 'ad-detail-printable',
            props: true,
            component: () => import(/* webpackChunkName: "ad-detail" */ '../views/AdDetailPrintable.vue')
        },
        {
            path: '/dealer/:dealerSlug',
            name: 'dealer',
            props: true,
            meta: {
                'dealer' : {
                    title: 'Dealer Details',
                }
            },
            component: () => import(/* webpackChunkName: "dealer" */ '../views/DealerDetails.vue')
        },
        {
            path: '/dealer/:dealerSlug/page/:page?',
            name: 'dealer',
            props: true,
            meta: {
                'dealer' : {
                    title: 'Dealer Details',
                }
            },
            component: () => import(/* webpackChunkName: "dealer" */ '../views/DealerDetails.vue')
        },
        {
            path: '/dealer/:dealerSlug/sortby/:sortby?',
            name: 'dealer',
            props: true,
            meta: {
                'dealer' : {
                    title: 'Dealer Details',
                }
            },
            component: () => import(/* webpackChunkName: "dealer" */ '../views/DealerDetails.vue')
        },
        {
            path: '/dealer/:dealerSlug/sortby/:sortby?/page/:page?',
            name: 'dealer',
            props: true,
            meta: {
                'dealer' : {
                    title: 'Dealer Details',
                }
            },
            component: () => import(/* webpackChunkName: "dealer" */ '../views/DealerDetails.vue')
        },
        {
            path: '/dealer/:dealerSlug/finance/:adId?/:deposit?',
            name: 'dealer-finance',
            props: true,
            meta: {
                'dealer-finance' : {
                    title: 'Finance',
                }
            },
            component: () => import(/* webpackChunkName: "dealer" */ '../views/DealerFinance.vue')
        },
        {
            path: '/dealer/:dealerSlug/reviews',
            name: 'dealer-reviews',
            props: true,
            meta: {
                'dealer-reviews' : {
                    title: 'Dealer Reviews',
                }
            },
            component: () => import(/* webpackChunkName: "dealer" */ '../views/DealerReviews.vue')
        },
        {
            path: '/dealer/:dealerSlug/directions',
            name: 'dealer-directions',
            props: true,
            meta: {
                'dealer-directions' : {
                    title: 'Directions',
                }
            },
            component: () => import(/* webpackChunkName: "dealer" */ '../views/DealerDirections.vue')
        },
        {
            path: '/car-reviews/:make?',
            name: 'car-reviews',
            props: true,
            meta: {
                'car-reviews' : {
                    title: 'Car Reviews',
                }
            },
            component: () => import(/* webpackChunkName: "car-reviews" */ '../views/CarReviews.vue')
        },
        {
            path: '/car-reviews/page/:paramPage?',
            name: 'car-reviews-paginated',
            props: true,
            meta: {
                'car-reviews-paginated' : {
                    title: 'Car Reviews',
                }
            },
            component: () => import(/* webpackChunkName: "car-reviews" */ '../views/CarReviews.vue')
        },
        {
            path: '/review/:slug',
            name: 'review',
            meta: {
                'review' : {
                    title: 'Car Reviews',
                }
            },
            component: () => import(/* webpackChunkName: "car-reviews" */ '../views/Review.vue')
        },
        {
            path: '/car-buyers-guide',
            name: 'car-buyers-guide',
            meta: {
                'car-buyers-guide' : {
                    title: 'Car Buyers Guide',
                }
            },
            component: () => import(/* webpackChunkName: "car-buyers-guide" */ '../views/CarBuyersGuide.vue')
        },
        {
            path: '/car-finance',
            name: 'car-finance',
            meta: {
                'car-finance' : {
                    title: 'Car Finance',
                }
            },
            component: () => import(/* webpackChunkName: "car-finance" */ '../views/CarFinance.vue')
        },
        {
            path: '/shortlist',
            name: 'shortlist',
            meta: {
                'shortlist' : {
                    title: 'Shortlist',
                }
            },
            component: () => import(/* webpackChunkName: "shortlist" */ '../views/Shortlist.vue')
        },
        {
            path: '/speedtest',
            name: 'speedtest',
            meta: {
                'speedtest' : {
                    title: 'Speedtest',
                }
            },
            component: () => import(/* webpackChunkName: "shortlist" */ '../views/SpeedTest.vue')
        },
        {
            path: '/contact-us',
            name: 'contact-us',
            meta: {
                'contact-us' : {
                    title: 'Contact Us',
                }
            },
            component: () => import(/* webpackChunkName: "contact-us" */ '../views/ContactUs.vue')

        },
        {
            path: '/privacy',
            name: 'privacy',
            meta: {
                'privacy' : {
                    title: 'Privacy',
                }
            },
            component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
        },
        {
            path: '/terms',
            name: 'terms',
            meta: {
                'terms' : {
                    title: 'Terms',
                }
            },
            component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue')
        },

        {
            path: '/news/page/:paramPage?',
            name: 'news-paginated',
            props: true,
            meta: {
                'news-paginated' : {
                    title: 'Industry News',
                }
            },
            component: () => import(/* webpackChunkName: "news" */ '../views/IndustryNews.vue')
        },
        {
            path: '/news',
            name: 'news',
            props: true,
            meta: {
                'news' : {
                    title: 'Industry News',
                }
            },
            component: () => import(/* webpackChunkName: "news" */ '../views/IndustryNews.vue')
        },
        {
            path: '/news-article/:slug',
            name: 'news-article',
            meta: {
                'news-article' : {
                    title: 'Industry News Article',
                }
            },
            component: () => import(/* webpackChunkName: "news" */ '../views/IndustryNewsArticle.vue')
        },
        {
            path: '/activate-email-alert/:status?',
            name: 'activate-email-alert',
            component: () => import(/* webpackChunkName: "news" */ '../views/ActivateEmailAlert.vue')
        },
        {
            path: '/deactivate-email-alert/:status?',
            name: 'deactivate-email-alert',
            component: () => import(/* webpackChunkName: "news" */ '../views/DeactivateEmailAlert.vue')
        },
        {
            path: '/widgets/quick-search',
            name: 'quick-search-widget',
            component: () => import(/* webpackChunkName: "news" */ '../views/QuickSearchWidget.vue')
        },
        {
            path: '/advertise/landing',
            name: 'advertise-landing',
            component: () => import(/* webpackChunkName: "adv-landing" */ '../views/AdvertiseLanding.vue')
        },
        {
            path: '/advertise/auth/login',
            name: 'advertise-login',
            component: () => import(/* webpackChunkName: "advertise-login" */ '../views/AdvertiseAuth.vue')
        },
        {
            path: '/advertise/auth/forgot-password',
            name: 'advertise-forgot-password',
            component: () => import(/* webpackChunkName: "advertise-forgot-password" */ '../views/AdvertiseForgotPassword.vue')
        },
        {
            path: '/advertise/private',
            name: 'private-advertiser',
            meta: {
                'private-advertiser' : {
                    title: 'Private Advertiser',
                }
            },
            component: () => import(/* webpackChunkName: "news" */ '../views/PrivateAdvertiser.vue')
        },
        {
            path: '/advertise/dealer/registration',
            name: 'dealer-registration',
            component: () => import(/* webpackChunkName: "news" */ '../views/DealerRegistration.vue')
        },
        {
            path: '/dealer-registration/success',
            name: 'dealer-registration-success',
            component: () => import(/* webpackChunkName: "news" */ '../views/DealerRegistrationSuccess.vue')
        },
        {
            path: '/advertise/checkout/success',
            name: 'advertise-checkout-success',
            component: () => import(/* webpackChunkName: "news" */ '../views/PrivateAdvertiser.vue')
        },
        {
            path: '/advertise/manage',
            alias: ['/advertise/edit', '/advertise/delete'],
            name: 'advertise-manage',
            component: () => import(/* webpackChunkName: "news" */ '../views/ManageAdvert.vue')
        },
        {
            path: '/advertise/confirm',
            name: 'advertise-confirm',
            component: () => import(/* webpackChunkName: "news" */ '../views/AdvertiseConfirmAction.vue')
        },
        {
            path: '/advertise/edit/success',
            name: 'advertise-edit-success',
            component: () => import(/* webpackChunkName: "news" */ '../views/PrivateAdvertiser.vue')
        },
        {
            path: '/advertise/delete/success',
            name: 'advertise-delete-success',
            component: () => import(/* webpackChunkName: "news" */ '../views/DeleteAdvertSuccess.vue')
        },
        {
            name: 'not-found',
            path: '/not-found',
            component: () => import(/* webpackChunkName: "news" */ '../views/PageNotFound.vue')
        },
        {
            path: "*",
            redirect: 'not-found'
        }
    ]

    const router = new VueRouter({
        mode: 'history',
        linkActiveClass: 'active',
        base: process.env.BASE_URL,
        routes,
        scrollBehavior (to) {
            let path = to.fullPath;
            if(path.search("used-cars") != -1){
                //Check if path has pagination. if so, scroll top
                if(path.search("page-") != -1){
                    return { x: 0, y: 0 };
                }
                return null;
            }
            return { x: 0, y: 0 };
        }
    })

    // add the beforeEach hook
    router.beforeEach((to, from, next) => {
        page(to.path);
        next();
    });
    return router;
}

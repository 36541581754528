export default {
    setFirstLevelName(state, val) {
        state.firstLevelName = val;
    },
    setSecondLevelName(state, val) {
        state.secondLevelName = val;
    },
    setThirdLevelName(state, val) {
        state.thirdLevelName = val;
    },
    setFourthLevelName(state, val) {
        state.fourthLevelName = val;
    },
    setLeafLevelName(state, val) {
        state.leafLevelName = val;
    },
    setPathLevelOne(state, val){
        state.pathLevelOne = val;
    },
    setPathLevelTwo(state, val){
        state.pathLevelTwo = val;
    },
    setPathLevelThree(state, val){
        state.pathLevelThree = val;
    },
    setPathLevelFour(state, val){
        state.pathLevelFour = val;
    },
    setLevelOne(state, val){
        state.levelOne = val;
    },
    setLevelTwo(state, val){
        state.levelTwo = val;
    },
    setLevelThree(state, val){
        state.levelThree = val;
    },
    setLevelFour(state, val){
        state.levelFour = val;
    }
};

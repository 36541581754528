<template>
    <div class="searchbymake" :class="modifier">
        <h1>Cars For Sale by Make</h1>
        <ul>
            <li><router-link :to="{path: '/used-cars/makes-alfa+romeo'}">Alfa Romeo</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-audi'}">Audi</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-bmw'}">BMW</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-chevrolet'}">Chevrolet</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-citroen'}">Citroen</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-dacia'}">Dacia</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-ds+automobiles'}">DS</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-fiat'}">Fiat</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-ford'}">Ford</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-honda'}">Honda</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-hyundai'}">Hyundai</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-jaguar'}">Jaguar</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-jeep'}">Jeep</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-kia'}">Kia</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-land+rover'}">Land Rover</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-lexus'}">Lexus</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-mazda'}">Mazda</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-mercedes+benz'}">Mercedes-Benz</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-mini'}">Mini</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-mitsubishi'}">Mitsubishi</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-nissan'}">Nissan</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-opel'}">Opel</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-peugeot'}">Peugeot</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-renault'}">Renault</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-saab'}">Saab</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-seat'}">SEAT</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-skoda'}">Skoda</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-subaru'}">Subaru</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-suzuki'}">Suzuki</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-toyota'}">Toyota</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-vauxhall'}">Vauxhall</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-volkswagen'}">Volkswagen</router-link></li>
            <li><router-link :to="{path: '/used-cars/makes-volvo'}">Volvo</router-link></li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "SearchByMake",
        props: ['modifier'],
    }
</script>

<style scoped>

</style>

<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<style lang="scss">
</style>
<script>
    export default {
        metaInfo: {
            meta: [
                { charset: 'utf-8' },
            ],
            script: [
            ],
            noscript: [
            ],
            name: 'App',
        },
        serverPrefetch() {
            this.$ssrContext.title = "Cars for Sale, Used Cars, Ireland, Second Hand, Car Website | Terrific.ie";
            this.$ssrContext.description = "Cars for sale across Ireland, with used cars from car dealers and private sellers. Popular categories include Electric Cars, 7 Seaters and Hybrids.";
            this.$ssrContext.keywords = "Cars for Sale, Used Cars, Cars, Second Hand Cars, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters, Land Rover";
            this.$ssrContext.robots = process.env.VUE_APP_ENV !== 'production' ? "noindex" : "all";
            this.$ssrContext.canonical = process.env.VUE_APP_BASE_URL + this.$router.currentRoute.path
                .replace(/\/sortby[^/]+/, '')
                .replace(/\/page[^/]+/, '')
                .replace(/\/s-1$/, '')
            ;
            this.$ssrContext.ogTitle = '';
            this.$ssrContext.ogDescription = '';
            this.$ssrContext.ogImage = 'https://api-on-prod-images-1vxkc1zf3todu.s3-eu-west-1.amazonaws.com/emails/terrific-logo-sharing.png';
            this.$ssrContext.ogUrl = '';
            this.$ssrContext.ldJson = '';
        },
        mounted(){
        },
    }
</script>

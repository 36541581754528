import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
    namespaced: true,
    state: () => ({
        levelOne: false,
        levelTwo: false,
        levelThree: false,
        levelFourth: false,
        firstLevelName: '',
        secondLevelName: '',
        thirdLevelName: '',
        fourthLevelName: '',
        leafLevelName: '',
        pathLevelOne: '',
        pathLevelTwo: '',
        pathLevelThree: '',
        pathLevelFour: '',
    }),
    getters,
    mutations,
    actions
};

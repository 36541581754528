import Vue from "vue";

export default {
    setDealer(state, {page, item}) {
        // Set meta information on the client side
        if(typeof document !== 'undefined'){
            document.title = item.dealer.seo.title;
            document.querySelector('meta[name="description"]').setAttribute("content", item.dealer.seo.description);
            document.querySelector('meta[name="keywords"]').setAttribute("content", item.dealer.seo.keywords);
            document.querySelector('meta[name="robots"]').setAttribute("content", "all");
        }
        Vue.set(state.dealer, page, item);
    },
    setDealerReviews(state, {page, item}){
        // Set meta information on the client side
        if(typeof document !== 'undefined'){
            // document.title = item.dealer.seo.title;
            // document.querySelector('meta[name="description"]').setAttribute("content", item.dealer.seo.description);
            // document.querySelector('meta[name="keywords"]').setAttribute("content", item.dealer.seo.keywords);
            // document.querySelector('meta[name="robots"]').setAttribute("content", "all");
        }
        Vue.set(state.dealerReviews, page, item);
    }
};

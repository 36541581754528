<template>
    <div class="homereviews__review homereviews__review--short">
    <router-link class="homereviews__review_link" :to="{name: 'review', params: { slug: this.review.slug }}">
            <div class="homereviews__review_imagewrapper">
                <div class="homereviews__review_image">
                    <img :src="this.imageUrl" width="200" height="133">
                </div>
            </div>
            <div class="homereviews__review_body">
                <h2>{{ this.review.title }}</h2>
                <small>{{ formattedCreatedAtDate }}</small>
                <span>{{shortenText}}</span>
            </div>
        </router-link>
    </div>
</template>

<script>
    var moment = require('moment')
    export default {
        name: "HomeReviewTile",
        props: ['review', 'index'],

        computed: {
            formattedCreatedAtDate() {
                return moment(this.review.created_at).format('Do MMMM YYYY');
            },

            imageUrl() {
                return this.review.thumbnail != null ? this.review.thumbnail : '';
            },

            shortenText() {
                if (this.review.overview_plain_text.length > 200)
                    return this.review.overview_plain_text.substring(0, 200) + '...';
                else
                    return this.review.overview_plain_text;
            }
        }
    }
</script>

<style scoped>

</style>

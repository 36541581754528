<template>
    <div>
    </div>
</template>

<script>
    export default {
        name: "SeoMeta",
        data: function() {
            return {
                slug: false,
                url: process.env.VUE_APP_API_URL + '/api/ad-elastic-filters?quickSearch=1',
                edjsHTML: require("editorjs-html"),
                robots: process.env.VUE_APP_ENV !== 'production' ? "noindex" : "all"
            }
        },
        computed: {
            seoData() {
                let res = null;
                let dealer = null;
                let news = null;


                switch (this.$route.name) {

                    case 'vehicle-detail':
                    case 'vehicle-detail-adv':
                        res = this.$store.state.ads.ad[this.$route.params.slug.split("/").pop()];

                        if(res != null && res.seo){
                            if(res && res.images && res.images.length > 0){
                                if(res.dealer.id==1){
                                    res.seo.ogImage = res.images[0].primaryURL.large;
                                }else{
                                    res.seo.ogImage = res.images[0].primaryURL.lg;
                                }
                            }else{
                                res.seo.ogImage = process.env.VUE_APP_BASE_URL + require('../../assets/img/terrific-logo-sharing.png');
                            }
                            res.seo.robots = this.robots;
                        }
                        break;

                    case 'dealer':
                        res = this.$store.state.dealers.dealer[this.$route.params.dealerSlug];
                        res = res.dealer;
                        res.seo.robots = this.robots;
                        break;

                    case 'dealer-finance':
                        dealer = this.$store.state.dealers.dealer[this.$route.params.dealerSlug];
                        if(typeof dealer.dealer === 'undefined'){
                            break;
                        }
                        res = {
                            'seo': {
                                'title': dealer ? dealer.dealer.display_name + ' Finance Application, Apply for finance with ' + dealer.dealer.display_name + ' | Terrific.ie' : '',
                                'description': dealer ? dealer.dealer.display_name + ' finance application form.' : '',
                                'keywords': 'Finance application, apply for finance, Car Dealer, Cars for Sale, Used Cars, Cars, Second Hand Cars',
                                'robots': this.robots
                            }
                        };
                        break;

                    case 'Home':
                        res = this.$store.state.searchResults[this.url];

                        break;

                    case 'review':
                        res = this.$store.state.reviews.review[this.$route.params.slug];
                        if(res != null){
                            res.seo.robots = this.robots;
                        }
                        break;

                    case 'news-article' :
                        news = this.$store.state.industrynews.industryNewsArticle[this.$route.params.slug];
                        res = {
                            'seo': {
                                'title': news.title + ' | Terrific.ie',
                                'description': this.getMyMainParagraph(),
                                'keywords': 'Industry News articles, Car Dealer, Cars for Sale, Used Cars, Cars, Second Hand Cars',
                                'robots': this.robots
                            }
                        };
                        break;

                    case 'advertise-edit-success':
                        res = {
                            'seo': {
                                'title': 'Advert Edit Success | Terrific.ie',
                                'description': 'Cars for sale across Ireland, with used cars from car dealers and private sellers. Popular categories include Electric Cars, 7 Seaters and Hybrids.',
                                'keywords': 'Cars for Sale, Used Cars, Cars, Second Hand Cars, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters, Land Rover',
                                'robots': this.robots
                            }
                        };
                        break;
                    case 'private-advertiser':
                        res = {
                            'seo': {
                                'title': 'Private Advertiser Steps | Terrific.ie',
                                'description': 'Cars for sale across Ireland, with used cars from car dealers and private sellers. Popular categories include Electric Cars, 7 Seaters and Hybrids.',
                                'keywords': 'Cars for Sale, Used Cars, Cars, Second Hand Cars, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters, Land Rover',
                                'robots': this.robots
                            }
                        };
                        break;

                    case 'advertise-checkout-success':
                        res = {
                            'seo': {
                                'title': 'Checkout Success | Terrific.ie',
                                'description': 'Cars for sale across Ireland, with used cars from car dealers and private sellers. Popular categories include Electric Cars, 7 Seaters and Hybrids.',
                                'keywords': 'Cars for Sale, Used Cars, Cars, Second Hand Cars, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters, Land Rover',
                                'robots': this.robots
                            }
                        };
                        break;

                    case 'advertise-landing':
                        res = {
                            'seo': {
                                'title': 'Advertise Your Car | Terrific.ie',
                                'description': 'Cars for sale across Ireland, with used cars from car dealers and private sellers. Popular categories include Electric Cars, 7 Seaters and Hybrids.',
                                'keywords': 'Cars for Sale, Used Cars, Cars, Second Hand Cars, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters, Land Rover',
                                'robots': this.robots
                            }
                        };
                        break;

                    case 'dealer-registration':
                        res = {
                            'seo': {
                                'title': 'Dealer Registration | Terrific.ie',
                                'description': 'Cars for sale across Ireland, with used cars from car dealers and private sellers. Popular categories include Electric Cars, 7 Seaters and Hybrids.',
                                'keywords': 'Cars for Sale, Used Cars, Cars, Second Hand Cars, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters, Land Rover',
                                'robots': this.robots
                            }
                        };
                        break;

                    case 'advertise-manage':
                        res = {
                            'seo': {
                                'title': 'Manage Your Ad | Terrific.ie',
                                'description': 'Cars for sale across Ireland, with used cars from car dealers and private sellers. Popular categories include Electric Cars, 7 Seaters and Hybrids.',
                                'keywords': 'Cars for Sale, Used Cars, Cars, Second Hand Cars, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters, Land Rover',
                                'robots': this.robots
                            }
                        };
                        break;

                    case 'advertise-confirm':
                        res = {
                            'seo': {
                                'title': 'Confirm Action | Terrific.ie',
                                'description': 'Cars for sale across Ireland, with used cars from car dealers and private sellers. Popular categories include Electric Cars, 7 Seaters and Hybrids.',
                                'keywords': 'Cars for Sale, Used Cars, Cars, Second Hand Cars, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters, Land Rover',
                                'robots': this.robots
                            }
                        };
                        break;

                    case 'advertise-delete-success':
                        res = {
                            'seo': {
                                'title': 'Advertise Deleted Successfully | Terrific.ie',
                                'description': 'Cars for sale across Ireland, with used cars from car dealers and private sellers. Popular categories include Electric Cars, 7 Seaters and Hybrids.',
                                'keywords': 'Cars for Sale, Used Cars, Cars, Second Hand Cars, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters, Land Rover',
                                'robots': this.robots
                            }
                        };
                        break;

                    default:
                }

                return res != null && res.seo != null ? res.seo : {
                        'title': 'Terrific.ie',
                        'description': 'Cars for sale across Ireland, with used cars from car dealers and private sellers. Popular categories include Electric Cars, 7 Seaters and Hybrids.',
                        'keywords': 'Cars for Sale, Used Cars, Cars, Second Hand Cars, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters, Land Rover',
                        'robots': this.robots
                };
            },
            article() {
                return this.$store.state.industrynews.industryNewsArticle[this.$route.params.slug];
            },
            myHtmlContent(){
                if(typeof  this.article.data_json === 'undefined' || !this.article.data_json){
                    return false;
                }

                // Parse this block in editorjs-html
                function customParser(block){
                    return `${block.data.html}`;
                }
                return this.edjsHTML({raw: customParser}).parse(JSON.parse(this.article.data_json));
            }
        },
        mounted(){
            if(typeof document !== 'undefined'){
                document.title = this.seoData.title;
                document.querySelector('meta[name="description"]').setAttribute("content", this.seoData.description);
                document.querySelector('meta[name="keywords"]').setAttribute("content", this.seoData.keywords);
                document.querySelector('meta[name="robots"]').setAttribute("content", this.robots);
            }
        },

        serverPrefetch () {
            this.$ssrContext.title =  this.seoData.title;
            this.$ssrContext.description = this.seoData.description;
            this.$ssrContext.keywords = this.seoData.keywords;
            this.$ssrContext.ogImage = this.seoData.ogImage;
            this.$ssrContext.ldJson = JSON.stringify(this.seoData.ldJson);
            this.$ssrContext.robots = this.seoData.robots  != null ? this.seoData.robots : this.robots;
        },
        methods:{
            checkParagraphExist(node){
                return /<p/g.test(node);
            },
            getMyMainParagraph(){
                let that = this;
                let res = '';
                if(this.myHtmlContent){
                    this.myHtmlContent.forEach(function(val, index){
                        if(that.checkParagraphExist(val)){
                            var match = val.match(/<\s*p[^>]*>([^<]*)<\s*\/\s*p\s*>/);
                            res = match ? match[1] : false;
                        }
                    });
                }
                return res;
            },
            getArticleMainParagraph(node){
                let that = this;
                let res = this.paragraphPlaceholder;
                if(!this.parsedIndustryNews(node)){
                    return this.handleReturn(res);
                }
                this.parsedIndustryNews(node).forEach(function (val){
                    if( that.checkParagraphExist(val)){
                        var match = val.match(/<\s*p[^>]*>([^<]*)<\s*\/\s*p\s*>/);
                        res = match[1];
                    }
                });
                return this.handleReturn(res);
            },
        }
    }
</script>

<style scoped>
</style>

import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL + '/api'

export function fetchReview(id) {
    return Vue.axios.get('/reviews/' + id).then((resp => resp.data.data)).catch((error) => {
        if(error.response.status == 404){
            return null;
        }
        return null;
    });
}

export function fetchReviews(page) {
    return Vue.axios.get('/reviews?page=' + page ).then((resp => resp.data))
}

export function fetchIndustryNews(page) {
    return Vue.axios.get('/industry-news/?page=' + page ).then((resp => resp.data))
} //fetchArticle

export function fetchIndustryNewsArticle(slug) {
    return Vue.axios.get('/industry-news/' + slug ).then((resp => resp.data)).catch((error) => {
        if(error.response.status == 404){
            return null;
        }
        return null;
    });
}


export function fetchPost(id) {
    return Vue.axios.get('/news/' + id).then((resp => resp.data.data))
}

export function fetchNews() {
    return Vue.axios.get('/news').then((resp => resp.data.data))
}

export function fetchMakes() {
    return Vue.axios.get('/ad-makes').then((resp => resp.data.data))
}

export function fetchMakeReviews(make) {
    return Vue.axios.get('/ad-makes/' + make + '/reviews').then((resp => resp.data))
}

export function fetchReviewMakes() {
    return Vue.axios.get('/review-make-list').then((resp => resp.data))
}

export function fetchAd(payload) {
    return Vue.axios.get('/listings/' + payload.slug).then((resp => resp.data.data)).catch((error) => {
        return error;
    })
}

export async function fetchDealer(slug) {
    let url = slug.split('&')[0];
    url = url.split('/')[0];
    return await Vue.axios.get('/dealer/' + url).then((resp => resp.data))
}

export async function fetchDealerReviews(slug) {
    let url = slug.split('&')[0];
    url = url.split('/')[0];
    return await Vue.axios.get('/dealer/' + url + '/reviews').then((resp => resp.data))
}

export async function fetchSearchMasterResults(url) {
    return await Vue.axios.get(url).then((resp => resp))
}

export async function fetchSearchResults(url) {
    return await Vue.axios.get(url).then((resp => resp.data.data))
}

export async function fetchMakeModelBySlug(url){
    return await Vue.axios.get(url).then((resp => resp.data))
}

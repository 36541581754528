<template>
  <div>
    <main-header></main-header>
    <seo-meta></seo-meta>
    <div class="quicksearch-overlay" v-on:click="closeOverlay()"  v-if="this.$store.state.quickSearchOverlay"></div>
    <div>
      <div class="wrapper">

        <div class="grid grid--homepage">

          <div class="homepage_flex_item homepage_flex_item--left">

            <div class="homepage_grid_item homepage_grid_item--quicksearch">
              <quick-search :monthlyPrice="false"></quick-search>
            </div>

            <div class="homepage_grid_item homepage_grid_item--ad_cta">
              <ad-cta modifier="ad_cta--mobile"></ad-cta>
            </div>

            <div class="homepage_grid_item homepage_grid_item--search_by_make">
              <search-by-make></search-by-make>
            </div>
          </div>

          <div class="homepage_flex_item homepage_flex_item--right">
              <home-categories></home-categories>
            <div class="homepage_grid_item homepage_grid_item--search_by_make">
              <search-by-make modifier="searchbymake--mobile"></search-by-make>
            </div>

          </div>

        </div>

        <div class="homepage_grid_item homepage_grid_item--ad_cta">
          <ad-cta></ad-cta>
        </div>

      </div>
      <home-reviews></home-reviews>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import MainHeader from '@/components/MainHeader.vue'
import SearchByMake from '@/components/SearchByMake.vue'
import QuickSearch from '@/components/QuickSearch'
import AdCta from '@/components/AdCta'
import HomeReviews from '@/components/HomeReviews'
import MainFooter from '@/components/MainFooter'
import SeoMeta from "@/components/partials/SeoMeta"
import HomeCategories from "@/components/HomeCategories";

export default {
    name: 'Home',
    components: {
        HomeCategories,
        MainHeader,
        SearchByMake,
        QuickSearch,
        AdCta,
        HomeReviews,
        MainFooter,
        SeoMeta
    },
    data: function () {
        return {
            title: "Cars for Sale, Used Cars, Ireland, Second Hand | Terrific.ie",
            description: "Cars for sale across Ireland, with used cars from car dealers and private sellers. Popular categories include Electric Cars, 7 Seaters and Hybrids.",
            keywords: "Cars for Sale, Used Cars, Cars, Second Hand Cars, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters, Land Rover",
            robots: process.env.VUE_APP_ENV != 'production' ? "noindex" : "all",
            ogImage: process.env.VUE_APP_BASE_URL + require('../assets/img/terrific-logo-sharing.png'),
            ldJson: {},
            url: process.env.VUE_APP_API_URL + '/api/ad-elastic-filters?quickSearch=1',
        }
    },
    metaInfo() {
        return {
            title: this.title,
        };
    },
    serverPrefetch () {
        this.$ssrContext.title = this.title;
        this.$ssrContext.description = this.description;
        this.$ssrContext.keywords = this.keywords;
        this.$ssrContext.robots = this.robots;
        this.$ssrContext.ogImage = this.ogImage;
        this.$ssrContext.ldJson = this.ldJson;
        return this.fetchSearchResults(this.url);
    },
    computed:{
        placeholderLazyImg(){
            return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
        }
    },
    methods:{
        closeOverlay(){
            this.$store.state.quickSearchOverlay = false;
        },
        async fetchSearchResults (url) {
            return await this.$store.dispatch('fetchQuickSearch', url);
        },
    }
}
</script>

import {fetchDealer} from "../../api";
import {fetchDealerReviews} from "../../api";

export default {
    fetchDealer({commit}, page) {
        return fetchDealer(page).then(item => {
            commit("setDealer", {page, item})
        })
    },
    fetchDealerReviews({commit}, page) {
        return fetchDealerReviews(page).then(item => {
            commit("setDealerReviews", {page, item})
        })
    },
};
